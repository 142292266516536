<template>
  <div class="userInfo">
    <h1>申请者档案</h1>
    <el-row>
      <el-col :xs="0" :sm="2" :md="3" :lg="5" :xl="6" />
      <el-col :xs="24" :sm="20" :md="18" :lg="14" :xl="12" class="table-wrap">
        <el-row :gutter="20">
          <el-col :span="6" :offset="2">ANA ID</el-col>
          <el-col :span="14">{{ MyInfo.anaid }}</el-col>
        </el-row>
        <el-divider />
        <el-row :gutter="20">
          <el-col :span="6" :offset="2">姓名</el-col>
          <el-col :span="14">{{ MyInfo.name }}</el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2">NetID</el-col>
          <el-col :span="14">{{ MyInfo.netid }}</el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2">学院</el-col>
          <el-col :span="14">{{ MyInfo.college }}</el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2">班级</el-col>
          <el-col :span="14">{{ MyInfo.class }}</el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2">角色</el-col>
          <el-col :span="14">{{ MyInfo.role_names_str }}</el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2" style="padding-top: 5px"
            >电子邮箱地址</el-col
          >
          <el-col :span="14"
            ><el-input
              type="email"
              v-model="upInfo.email"
              @click="changeStatus"
          /></el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2" style="padding-top: 5px">电话</el-col>
          <el-col :span="14">
            <el-row style="margin-top: 0px; margin-bottom: 0px"
              ><el-col :span="2" style="padding-top: 7px"><span>+</span></el-col
              ><el-col :span="6"
                ><el-input
                  type="text"
                  v-model="upInfo.areaCode"
                  @click="changeStatus"
                  id="areaCode"
                  placeholder="86" /></el-col
              ><el-col :span="2" style="padding-top: 5px"><span>-</span></el-col
              ><el-col :span="14"
                ><el-input
                  type="text"
                  v-model="upInfo.phoneNumber"
                  @click="changeStatus"
              /></el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2" style="padding-top: 7px">QQ</el-col>
          <el-col :span="14"
            ><el-input type="text" v-model="upInfo.qq" @click="changeStatus"
          /></el-col>
        </el-row>
        <el-divider />
        <el-row>
          <el-col :span="6" :offset="2">校区</el-col>
          <el-col :span="14">
            <form @click="changeStatus">
              <input type="radio" value="兴庆" v-model="upInfo.campus" />兴庆
              <input type="radio" value="雁塔" v-model="upInfo.campus" />雁塔
              <input
                type="radio"
                value="创新港"
                v-model="upInfo.campus"
              />创新港
            </form></el-col
          >
        </el-row>
      </el-col>
    </el-row>

    <el-button
      type="primary"
      id="upButton"
      :disabled="InfoNotChanged"
      @click="updateInfo"
    >
      更新联系方式
    </el-button>
  </div>
</template>

<script>
import axioService from "@/services/axioService.js";
export default {
  name: "UserInfo",
  inject: ["loginRedirectURL"],
  data() {
    return {
      InfoNotChanged: true,
      MyInfo: Object,
      refreshCampus: true,
      upInfo: {
        email: "",
        areaCode: "",
        phoneNumber: "",
        phone: null,
        qq: "",
        campus: "",
      },
    };
  },
  created() {
    axioService
      .getData("api/user")
      .then((res) => {
        if (res.data.code === 0) {
          this.MyInfo = res.data.data;
          let role_names = [];
          for (const role of this.MyInfo.roles) {
            role_names.push(role.role_name);
          }
          this.MyInfo.role_names_str = role_names.join(", ");
          this.upInfo.email = this.MyInfo.email;
          this.upInfo.phone = this.MyInfo.phone.split(" ");
          this.upInfo.areaCode = this.upInfo.phone[0].split("+")[1];
          this.upInfo.phoneNumber = this.upInfo.phone[1];
          this.upInfo.qq = this.MyInfo.qq;
          this.upInfo.campus = this.MyInfo.campus;
        } else {
          alert(res.data.msg);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 403) {
          window.location.replace(this.loginRedirectURL);
        }
      });
  },
  methods: {
    changeStatus() {
      this.InfoNotChanged = false;
    },
    updateInfo() {
      if (this.upInfo.phoneNumber) {
        if (!this.upInfo.areaCode) {
          this.upInfo.areaCode = "86";
        }
        this.upInfo.phone =
          "+" + this.upInfo.areaCode + " " + this.upInfo.phoneNumber;
      } else {
        this.upInfo.phone = "";
      }
      axioService
        .patchData("api/user", this.upInfo)
        .then((res) => {
          // console.log(res);
          if (res.data.code === 0) {
            alert("更新成功");
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          } else {
            alert("更新失败");
          }
        });
    },
  },
};
</script>

<style scoped>
#areaCode {
  width: 2em;
}

table {
  max-width: 35em;
}
</style>

<style lang="scss">
.el-row {
  margin-bottom: 10px;
  margin-top: 10px;
}

.el-row:first-child {
  margin-top: 30px;
}
// .el-row:last-child {
//   margin-bottom: 0;
// }
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: var(--ep-c-bg-row);
}

.entry {
  background: #8da8db;
  color: #5f5f5f;
  align-items: center;
}
</style>
