<template>
  <!-- <nav v-show="$route.name != 'form'">
    <router-link to="/">申请状态</router-link> |
    <router-link to="/user">我的信息</router-link> |
    <router-link to="/about">关于</router-link>
  </nav> -->
  <el-container>
    <el-affix>
      <el-header
        style="padding=0; background: var(--el-color-primary-light-9); margin-top=0px; padding-top: 0px; padding-bottom: 0px; position: relative; top: 0px;"
      >
        <el-menu
          router
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          ellipsis="false"
          style="background: var(--el-color-primary-light-9); margin-top=0px; padding-top: 0px; padding-bottom: 0px;"
        >
          <el-menu-item index="/">申请状态</el-menu-item>
          <el-menu-item index="/user">我的信息</el-menu-item>
          <!-- <div class="flex-grow" /> -->
          <el-menu-item index="/about">关于我们</el-menu-item>
        </el-menu>
      </el-header>
    </el-affix>
    <el-main><el-backtop :right="100" :bottom="100" /><router-view /></el-main>
    <el-footer><FooterComp /></el-footer
  ></el-container>
</template>

<script>
import FooterComp from "@/components/FooterComp.vue";
export default {
  components: {
    FooterComp,
  },
};
</script>

<style>
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style>
.flex-grow {
  flex-grow: 1;
}
</style>
