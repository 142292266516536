<template>
  <div class="InterviewComp" v-if="interview.schedules">
    <p style="color: green">id: {{ interview.id }}</p>
    <p style="color: green">Interview_raw: {{ interview.interview }}</p>
    <p style="color: green">departmentID: {{ interview.department }}</p>

    <p>面试地点: {{ interview.location }}</p>
    <p>开始时间: {{ start_time_local }}</p>
    <p>总时长: {{ interview.last }}</p>
    <p>每人时长：{{ interview.last_pp }}</p>

    <div style="background-color: #ddd" v-if="!scheduleComplete">
      <p>自动安排人员(schedule):</p>
      <button @click="autoSchedule()">自动安排</button>
      <p>------------------------------------------------</p>
      <p>手动安排人员(schedule):</p>
      <ul class="box">
        <li
          v-for="(people, index) of availableScheduleList"
          :class="{ checked: index == iSelected }"
          @click="iSelected = index"
          :key="people.caseid"
        >
          {{ people.name }}&lt;-&gt;{{ people.netid }}
        </li>
      </ul>
      <br />
      此人面试开始时间:
      <br />
      <input type="datetime-local" v-model="start_time" />
      <button @click="schedule()">安排</button>
    </div>

    <div v-if="interview.schedules.length">
      <button @click="displayScheduledList = !displayScheduledList">
        展开/隐藏 已安排面试的人员列表
      </button>
      <button @click="printList">打印签到表</button>

      <div v-if="displayScheduledList">
        <p>已安排面试的人员:</p>
        <PeopleComp
          v-for="(people, idx) in interview.schedules"
          :key="idx"
          :people="people"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PeopleComp from "@/components/InterviewPeopleComp.vue";
import axioService from "@/services/axioService.js";
export default {
  name: "InterviewComp",
  components: { PeopleComp },
  data() {
    return {
      displayScheduledList: true,
      availableScheduleList: [],
      iSelected: 0,
      scheduleComplete: false,
      start_time: null,
      start_time_local: null,
    };
  },
  props: {
    interview: Object,
  },
  created() {
    this.start_time_local = new Date(this.interview.start_time).toString();
    this.getAvailableScheduleList();
  },
  methods: {
    printList() {
      fetch("api/interview/" + this.interview.id + "/pdf").then((res) =>
        res.blob().then((blob) => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          var filename = "interview.pdf";
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    },
    getAvailableScheduleList() {
      let getURL = "api/interview/" + this.interview.id + "/available_cases";
      axioService
        .getData(getURL)
        .then((res) => {
          if (res.data.code === 0) {
            this.availableScheduleList = res.data.data.cases;
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    autoSchedule() {
      let postURL = "/api/interview/" + this.interview.id + "/schedule";
      axioService
        .postData(postURL, {
          case_id: "auto",
          start_time: 0,
        })
        .then((res) => {
          if (res.data.code === 0) {
            alert("安排成功");
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    schedule() {
      let caseId = this.availableScheduleList[this.iSelected].caseid;
      let startTime = Math.floor(new Date(this.start_time).getTime() / 1000);
      let tmp = {
        case_id: caseId,
        start_time: startTime,
      };
      let postURL = "/api/interview/" + this.interview.id + "/schedule";
      axioService
        .postData(postURL, tmp)
        .then((res) => {
          if (res.data.code === 0) {
            alert("安排成功");
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
  },
};
</script>

<style scoped>
.InterviewComp {
  width: 85%;
  max-width: 800px;
  min-height: 60px;
  margin: 20px auto;
  padding: 8px;
  background-color: beige;
  border: 2px solid black;
  border-radius: 20px;
}

ul {
  padding: 0;
  list-style: none;
  margin: 8px 8px;
}
li {
  height: 45px;
  display: inline-block;
  border-radius: 8px;
  border: 1px #000 solid;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  transition: all 0.3s linear;
  margin: 7px;
  font-size: 0.8em;
}
li:hover {
  background-color: #777;
  color: #fff;
  border: 1px #fff solid;
}
li.checked {
  background-color: #000;
  color: #fff;
  border: 1px #fff solid;
}
button {
  padding: auto 0.3em;
  margin: 1em 0.8em;
  min-width: 5em;
  min-height: 3em;
}
</style>
