<template>
  <h1 v-if="!isAdmin">Permission denied</h1>
  <h1 v-if="!isAdmin">仅面试官可见</h1>
  <h1 v-if="isAdmin">
    <p>😀哈喽哈喽，听得到吗😊！</p>
    <p>如果看得到说明你具有面试安排调度的权限🥰🥰🥰</p>
    <p>如果你不是社团部长或社长团成员,请记得反馈给我们哦😘</p>
  </h1>
  <div v-if="isAdmin">
    <div style="background-color: #ddd">
      轮次:
      <select v-model="newInterview.raw" @change="selectRaw">
        <option value="" disabled>轮次</option>
        <option
          v-for="raw in this.interviewRaw"
          :value="raw.id"
          :key="raw.id"
          :label="
            raw.round_str +
            '/' +
            raw.organization_str +
            '/' +
            raw.department_str +
            '/' +
            raw.desc
          "
        ></option>
      </select>
      <br />
      开始时间:
      <input
        type="datetime-local"
        v-model="raw_start_time"
        @change="timeConvert()"
      />
      <br />
      持续总时长:
      <input type="number" style="width: 6em" v-model="newInterview.last" />
      分钟
      <br />
      每人面试时长:
      <input type="number" style="width: 6em" v-model="newInterview.last_pp" />
      分钟
      <br />
      面试地点:
      <select v-model="newInterview.location">
        <option value="" disabled>地点</option>
        <option
          v-for="(lct, i) in this.locations"
          :value="lct"
          :key="i"
          :label="lct"
        ></option>
      </select>
      <br />
      <div style="background-color: pink">
        <p>raw:{{ newInterview.raw }}</p>
        <p>round:{{ newInterview.round }}</p>
        <p>org:{{ newInterview.organization }}</p>
        <p>dept:{{ newInterview.department }}</p>
        <p>start_time: {{ raw_start_time }}</p>
        <p>start_time_convert: {{ newInterview.start_time }}</p>
        <p>last: {{ newInterview.last }}</p>
        <p>last_pp: {{ newInterview.last_pp }}</p>
        <p>location: {{ newInterview.location }}</p>
      </div>
      <button @click="createInterview()">创建新的面试</button>
    </div>
    <br />
    <div style="background-color: #ddd" v-if="interviewList.length">
      <p>interviewList:</p>
      <InterviewComp
        v-for="interview in interviewList"
        :key="interview.id"
        :interview="interview"
      />
    </div>
  </div>
</template>

<script>
import axioService from "@/services/axioService.js";
import InterviewComp from "@/components/InterviewComp.vue";
export default {
  name: "InterviewManage",
  inject: ["loginRedirectURL"],
  components: { InterviewComp },
  data() {
    return {
      isAdmin: false,
      MyInfo: null,
      locations: [
        "兴庆校区东2社团办公室",
        "兴庆校区东16社团办公室",
        "兴庆校区钱学森图书馆西南一研修间",
        "兴庆校区钱学森图书馆西南二研修间",
        "兴庆校区钱学森图书馆东南一研修间",
        "兴庆校区钱学森图书馆东南二研修间",
        "雁塔校区10号楼社团办公室",
        "兴庆校区七彩阁社办（西南门师生综合事务服务大厅旁）",
        "其他",
      ],
      raw_start_time: null,
      interviewRaw: null,
      interviewList: [],
      newInterview: {
        raw: "id",
        round: "id",
        organization: "id",
        department: "id",
        start_time: 0,
        last: 0,
        last_pp: 0,
        location: "青青草原",
      },
    };
  },
  created() {
    axioService
      .getData("api/user")
      .then((res) => {
        if (res.data.code === 0) {
          this.MyInfo = res.data.data;
          this.isAdmin = this.MyInfo.can_manage;
        } else {
          alert(res.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.replace(this.loginRedirectURL);
        }
      });
    this.getInterviewRaw();
    this.getInterview();
  },
  methods: {
    getInterview() {
      axioService
        .getData("/api/interview")
        .then((res) => {
          if (res.data.code === 0) {
            this.interviewList = res.data.data.interviews;
            this.interviewList.map((e) => {
              this.getSchedule(e.id);
            });
            // console.log(this.interviewList);
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    selectRaw(e) {
      this.interviewRaw.map((item) => {
        if (item.id == e.target.value) {
          this.newInterview.round = item.round;
          this.newInterview.organization = item.organization;
          this.newInterview.department = item.department;
        }
      });
    },
    getInterviewRaw() {
      axioService
        .getData("/api/interview_raw")
        .then((res) => {
          if (res.data.code === 0) {
            this.interviewRaw = res.data.data.interviews;
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    getSchedule(interviewID) {
      let getURL = "/api/interview/" + interviewID + "/schedule";
      axioService
        .getData(getURL)
        .then((res) => {
          if (res.data.code === 0) {
            this.interviewList.map((e) => {
              if (e.id == interviewID) {
                e.schedules = res.data.data.schedules;
              }
            });
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    createInterview() {
      axioService
        .postData("/api/interview", this.newInterview)
        .then((res) => {
          if (res.data.code === 0) {
            alert("创建面试安排成功，请进一步调度");
            // window.location.reload();
            this.getInterview();
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    timeConvert() {
      let offset = new Date().getTimezoneOffset() * 60;
      //eg in China is -480*60
      offset = 0;
      this.newInterview.start_time =
        Math.floor(new Date(this.raw_start_time).getTime() / 1000) + offset;
    },
  },
};
</script>

<style scoped></style>
