import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import "element-plus/theme-chalk/src/message.scss";
import "element-plus/theme-chalk/src/index.scss";

let app = createApp(App);
app.provide("loginRedirectURL", "https://apply.xjtuana.cn/api/login");

app.use(ElementPlus).use(store).use(router).mount("#app");
