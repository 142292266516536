<template>
  <div class="ApplyState">
    <el-row>
      <el-col :xs="1" :sm="2" :md="3" :lg="5" :xl="6" />
      <el-col :xs="22" :sm="20" :md="18" :lg="14" :xl="12" class="table-wrap">
        <div v-if="states != null">
          <div v-if="isAdmin" style="background-color: aqua">
            <a href="interview">面试安排管理页面</a>
            <br />
            <h3>filter By:</h3>
            <select
              placeholder="招新轮次"
              v-model="filterBy.round"
              @change="fetchStates()"
            >
              <option
                v-for="round in rounds"
                :value="round.id"
                :label="round.name"
                :key="round.id"
              >
                {{ round.name }}
              </option>
            </select>
            <div>
              <input
                type="checkbox"
                name="sortByTime"
                v-model="sortBy.time"
                @change="fetchStates()"
              />
              <label for="sortByTime">Sort By Time</label>
            </div>
          </div>
          <div id="newApply">
            <el-form>
              <p>新建申请：</p>
              <el-row
                ><el-col :span="6">
                  <el-select
                    v-model="newForm.round"
                    @change="uporgs(newForm.round)"
                    placeholder="请选择招新轮次"
                  >
                    <el-option value="" disabled>轮次</el-option>
                    <el-option
                      v-for="round in rounds"
                      :value="round.id"
                      :key="round.id"
                      :label="round.name"
                    >
                      {{ round.name }}
                    </el-option>
                    <!--<option :value="1">2022-2023年度</option>-->
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select
                    v-model="newForm.organization"
                    @change="updpts(newForm.organization)"
                    placeholder="请选择招新社团"
                  >
                    <el-option value="" disabled>社团</el-option>
                    <el-option
                      :value="org.id"
                      v-for="org in orgs"
                      :key="org.id"
                      :label="org.name"
                    >
                      {{ org.name }}
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select
                    v-model="newForm.department"
                    placeholder="请选择招新部门"
                  >
                    <el-option value="" disabled>部门</el-option>
                    <el-option
                      :value="dpt.id"
                      v-for="dpt in dpts"
                      :key="dpt.id"
                      :label="dpt.name"
                    >
                      {{ dpt.name }}
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select
                    v-model="newForm.order"
                    @change="recheck"
                    placeholder="请选择志愿顺序"
                  >
                    <el-option value="" selected disabled>志愿顺序</el-option>
                    <el-option :value="od" v-for="od in maxChoice" :key="od">
                      {{ od }}
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form>
            <br />
            <el-button
              @click="newApply"
              :disabled="canCreateCase == false"
              style="margin-bottom: 20px"
            >
              创建新的申请
            </el-button>
            <!-- 用户点击后，后端创建新的caseID，统一在"/"下根据caseID判断，对应不同部门和校区问卷表单 -->
            <div v-if="newForm.round != null" style="margin-bottom: 20px">
              {{ getRoundById(newForm.round).msg }}
            </div>
          </div>
          <h3>你好, {{ states.name }}同学</h3>
          <h4>
            共有<span style="color: red"> {{ states.cases.length }} </span
            >个申请记录
          </h4>
          <StateComp
            v-for="(state, idx) in states.cases"
            :key="idx"
            :state="state"
          />
          <!-- <a href="/">创建新的申请</a> -->
          <br />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import StateComp from "@/components/StateComp.vue";
import axioService from "@/services/axioService.js";
export default {
  name: "ApplyState",
  inject: ["loginRedirectURL"],
  components: { StateComp },
  data() {
    return {
      states: null,
      states_raw_cases: null,
      rounds: null,
      orgs: null,
      dpts: null,
      items: [],
      canCreateCase: false,
      maxChoice: 0,
      newForm: {
        round: null,
        department: null,
        organization: null,
        order: null,
      },
      filterBy: {
        round: "",
      },
      sortBy: {
        time: "",
      },

      isAdmin: false,
    };
  },
  created() {
    this.checkUserInfoComplete();
    axioService
      .getData("/api/round")
      .then((res) => {
        if (res.data.code === 0) {
          this.rounds = res.data.data.rounds;
          for (let i = 0; i < this.rounds.length; i++) {
            this.items.push(this.rounds[i]);
            for (let j = 0; j < this.rounds[i].organizations.length; j++) {
              this.items.push(this.rounds[i].organizations[j]);
              for (
                let k = 0;
                k < this.rounds[i].organizations[j].department.length;
                k++
              ) {
                this.items.push(this.rounds[i].organizations[j].department[k]);
              }
            }
          }
          this.fetchStates();
        } else {
          // console.log(res);
          alert(res.data.msg);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 403) {
          window.location.replace(this.loginRedirectURL);
        }
      });
  },
  methods: {
    fetchStates() {
      let url = "/api/case?";
      if (this.filterBy.round != "") {
        url = url + "round=" + this.filterBy.round + "&";
      }
      if (this.sortBy.time != "") {
        url = url + "sort=time&";
      }
      axioService
        .getData(url)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 0) {
            this.states = res.data.data;
            for (let i = 0; i < this.states.cases.length; i++) {
              this.states.cases[i].organizationStr = this.findItemById(
                this.states.cases[i].organization
              ).name;
              this.states.cases[i].departmentStr = this.findItemById(
                this.states.cases[i].department
              ).name;
              this.states.cases[i].roundStr = this.findItemById(
                this.states.cases[i].round
              ).name;
            }
            this.states_raw_cases = this.states.cases;
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    findItemById(id) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          return this.items[i];
        }
      }
    },
    getRoundById(id) {
      for (let i = 0; i < this.rounds.length; i++) {
        if (this.rounds[i].id === id) {
          return this.rounds[i];
        }
      }
    },
    getOrgById(id) {
      for (let i = 0; i < this.orgs.length; i++) {
        if (this.orgs[i].id === id) {
          return this.orgs[i];
        }
      }
    },
    // getDptById(id) {
    //   for (let i = 0; i < this.dpts.length; i++) {
    //     if (this.dpts[i].id === id) {
    //       return this.dpts[i];
    //     }
    //   }
    // },
    uporgs(roundId) {
      let round = this.getRoundById(roundId);
      if (round.open === false) {
        this.canCreateCase = false;
      }
      this.orgs = round.organizations;
      // console.log(this.orgs);
      this.dpts = null;
      this.maxChoice = 0;
    },
    updpts(orgId) {
      let org = this.getOrgById(orgId);
      this.maxChoice = org.availableChoice;
      // console.log(org);
      this.dpts = [];
      for (let i = 0; i < org.department.length; i++) {
        const e = org.department[i];
        if (e.open) {
          this.dpts.push(e);
        }
      }
      // console.log(this.dpts);
    },
    recheck() {
      let round = this.getRoundById(this.newForm.round);
      if (round === null || round.open === false) {
        this.canCreateCase = false;
      } else {
        this.canCreateCase = true;
      }
    },
    newApply() {
      if (this.newForm.department !== "") {
        axioService
          .postData("/api/case", {
            round: this.newForm.round,
            department: this.newForm.department,
            organization: this.newForm.organization,
            caseType: this.newForm.order,
          })
          .then((res) => {
            // console.log(res);
            if (res.data.code === 0) {
              alert("创建申请成功，请进一步填写");
              window.location.reload();
            } else {
              alert(res.data.msg);
            }
          })
          .catch((error) => {
            // console.log(error);
            alert(error);
            if (error.response.status === 403) {
              window.location.replace(this.loginRedirectURL);
            }
          });
        // this.$router.push({
        //   name: "FormList",
        //   params: { formID: "e4c207ec-11bb-46b9-b76d-f5a355dec088" },
        // });
      } else {
        alert("请填写意愿部门等信息");
      }
    },
    checkUserInfoComplete() {
      axioService
        .getData("api/user")
        .then((res) => {
          if (res.data.code === 0) {
            let MyInfo = res.data.data;
            if (
              MyInfo.email === "" ||
              MyInfo.phone === "" ||
              MyInfo.qq === "" ||
              MyInfo.campus === ""
            ) {
              alert("请先完善个人信息后再进行申请");
              this.$router.push("/user");
            } else {
              this.isAdmin = MyInfo.can_manage;
            }
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
  },
};
</script>

<style scoped>
a {
  /*color: red;*/
  font-size: 1.2em;
}
</style>

<style>
#newApply {
  background: var(--el-color-primary-light-8);
  border: 1px solid #5f5f5f;
  align-items: center;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
