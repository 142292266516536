<template>
  <div class="about">
    <h1>学生网络管理协会网络申请系统</h1>
    <h3>欢迎加入我们</h3>
    <img
      alt="ANA logo"
      src="../assets/logo.png"
      style="width: 30%; max-width: 15em"
    />
    <br />
    <br />
    <br />
    <HR align="center" width="75%" color="gray" SIZE="1"></HR>
    <h4>运维部</h4>
    <ul>
      <li>
        运维部是社团的核心力量，设有3个分部：兴庆运维部、雁塔运维部、创新港运维部，分别负责兴庆校区（含教学二区）、雁塔校区（含二附院）和创新港校区的学生区网络运行维护工作。
      </li>
      <li>
        在这里，我们有学校网络中心的官方系统培训，也有前辈成员的悉心经验指导。
      </li>
      <li>
        在这里，我们掌握网络基本原理和基础知识，了解校园网络设备分布与配置，领略网络运维工作的奥秘与魅力。
      </li>
      <li>
        在这里，我们学习如何排查故障，如何解决各种网络问题，从电脑小白蜕变为帮助同学排忧解难的出色网管。
      </li>
    </ul>
    <img
      alt="ANA logo"
      src="/assets/about/maintenance.jpg"
      style="width: 90%; max-width: 40em"
    />
    <br />
    <br />
    <HR align="center" width="75%" color="gray" SIZE="1"></HR>
    <h4>研发部</h4>
    <ul>
      <li>
        研发部为社团提供技术支持，包括开发社团网站，完善各项功能，维护社团服务器，学校镜像站，智能答疑机器人。
      </li>
      <li>这里有技术大牛，也有菜鸟萌新，但都有一颗热爱技术的心。</li>
      <li>
        学技术绝不只是说说而已，不花时间去码代码、去实践、去思考、去遇到问题、去解决问题，永远不会进步，永远只是小白。
      </li>
      <li>
        打酱油永远无法真正成为研发部的一员，无论起点高低，只有肯努力，肯下功夫，才能融入我们的研发团队。
      </li>
    </ul>
    <img
      alt="ANA logo"
      src="/assets/about/coding.jpg"
      style="width: 90%; max-width: 40em"
    />
    <br />
    <br />
    <HR align="center" width="75%" color="gray" SIZE="1"></HR>
    <h4>宣传部</h4>
    <ul>
      <li>
        宣传部是社团的形象设计师，是对外交流的窗口，展现网管会的社团风采。
      </li>
      <li>
        审美是我们的基本功，PS、AI、CD只是基本工具，表情包、斗图大战完全不在话下。
      </li>
      <li>
        运营微信公众号，提供社团的新媒体支持，为同学们提供投诉渠道，解答疑难问题。
      </li>
      <li>发布通知，文案构思，逗比小编一抓一大把。</li>
      <li>设计海报，宣传活动，“这次活动来多少人就看你们了。”</li>
      <li>有灵感，更有实现灵感的执行力；有脑洞，更有展现脑洞的大舞台。</li>
    </ul>
    <img
      alt="ANA logo"
      src="/assets/about/broadcast.jpg"
      style="width: 90%; max-width: 40em"
    />
  </div>
</template>

<style scoped>
ul {
  width: 85%;
  max-width: 38em;
  margin: 1em auto;
}
li {
  text-align: left;
  margin: 0.5em 0;
}
</style>
