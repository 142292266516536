<template>
  <div class="home" v-if="this.forms">
    <h1>{{ forms.name }}</h1>
    <h4 v-html="forms.desc"></h4>
    <el-row>
      <el-col :xs="1" :sm="2" :md="3" :lg="5" :xl="6" />
      <el-col :xs="22" :sm="20" :md="18" :lg="14" :xl="12" class="table-wrap">
        <!--<el-row>
          <el-col :xs="1" :sm="2" :md="3" :lg="5" :xl="6" />
          <el-col
            :xs="22"
            :sm="20"
            :md="18"
            :lg="14"
            :xl="12"
            class="table-wrap"
          >
            <img alt="ANA logo" src="../assets/logo.png" style="width: 40%" />
          </el-col>
        </el-row>-->
        <!-- <el-form :model="form" action="" label-width="120px"> -->
        <CardComp
          v-for="(card, idx) in forms.data"
          :key="idx"
          :card="card"
          :Fidx="idx"
          :ref="'p' + card.id"
        />
        <!-- </el-form> -->
        <el-row>
          <el-col :xs="1" :sm="2" :md="3" :lg="5" :xl="6" />
          <el-col
            :xs="22"
            :sm="20"
            :md="18"
            :lg="14"
            :xl="12"
            class="table-wrap"
          >
            <el-button type="primary" @click="postData">提交表单</el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="1" :sm="2" :md="3" :lg="5" :xl="6" />
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import CardComp from "@/components/CardComp.vue";
import axioService from "@/services/axioService.js";
export default {
  name: "FormList",
  inject: ["loginRedirectURL"],
  components: {
    CardComp,
  },
  data() {
    return {
      forms: {
        formVersion: "114514v1",
        name: "Form Loading",
        desc: "Loading",
        data: [],
      },
      formID: null,
      caseID: null,
      pData: {},
      // substituted by formData
    };
  },
  created() {
    // console.log(this.$route.params);
    this.formID = this.$route.params.formID;
    this.caseID = this.$route.params.caseID;
    if (!this.formID) {
      this.$router.push({
        name: "ApplyState",
      });
    } else {
      this.getList();
    }
  },
  methods: {
    postData() {
      let payload = [];

      const len = this.forms.data.length;
      for (let i = 0; i < len; i++) {
        let id = this.forms.data[i].id;
        let v = this.$refs["p" + id][0].value;
        if (Array.isArray(v)) {
          payload.push({ id: id, data: v });
        } else {
          payload.push({ id: id, data: [v] });
        }
      }
      axioService
        .postData("api/form/" + this.formID, {
          case_id: this.caseID,
          form_id: this.formID,
          content: JSON.stringify({ ans: payload }),
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code === 0) {
            alert("已提交申请");
            this.$router.push("/");
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          } else if (error.response.status === 413) {
            //案例:有人简历大小刚好是1M,服务器对超过1M的拒绝了
            //(后端没考虑表单其他内容也占大小)目前服务器Limit改为1.1M
            alert("简历和表单其他内容总大小超过1.1M，请限制简历大小。");
          } else {
            alert(error);
          }
        });
    },
    getList() {
      axioService
        .getData("api/form/" + this.formID)
        .then((res) => {
          console.log(res.data);
          if (res.data.code === 0) {
            this.forms = res.data.data;
          } else {
            alert(res.data.code);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
  },
};
</script>
