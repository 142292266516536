<template>
  <el-button
    type="primary"
    v-if="action.type === 'form'"
    @click="gotoForm(action.data, caseID)"
  >
    {{ action.desc }}
  </el-button>

  <el-button
    type="primary"
    v-if="action.type === 'interview_confirm'"
    @click="interviewConfirm(action.data, caseID)"
  >
    {{ action.desc }}
  </el-button>

  <!-- admin -->
  <div
    style="margin: 10px; padding: 0; background-color: aqua"
    v-if="action.type === 'admin'"
  >
    <input type="text" v-model="logHint" />
    <el-button type="primary" @click="adminAction(action.data, caseID)">{{
      action.desc
    }}</el-button>
  </div>
</template>

<script>
import axioService from "@/services/axioService.js";
export default {
  name: "ActionComp",
  data() {
    return {
      logHint: "",
    };
  },
  props: {
    action: Object,
    caseID: String,
  },
  created() {},
  methods: {
    gotoForm(paraFormID, paraCaseID) {
      // console.log(this.action);
      this.$router.push({
        name: "FormList",
        params: { formID: paraFormID, caseID: paraCaseID },
        // params: { formID: "e4c207ec-11bb-46b9-b76d-f5a355dec088" },
      });
    },

    adminAction(paraActionID, paraCaseID) {
      let apiUrl = "api/admin/" + paraActionID;
      let tmp = { case_id: paraCaseID, hint: this.logHint };
      axioService
        .postData(apiUrl, tmp)
        .then((res) => {
          // console.log(res);
          if (res.data.code === 0) {
            alert("提交成功");
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 403) {
            alert("403 Error");
            window.location.replace(this.loginRedirectURL);
          } else {
            alert("提交失败,网络原因且非403错误");
          }
        });
    },
    interviewConfirm(paraConfirmID, paraCaseID) {
      let apiUrl = "api/interview/" + paraConfirmID + "/confirm";
      let tmp = { caseid: paraCaseID };
      axioService
        .postData(apiUrl, tmp)
        .then((res) => {
          if (res.data.code === 0) {
            alert("确认成功");
            location.reload();
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert("403 Error");
            window.location.replace(this.loginRedirectURL);
          } else {
            alert("提交失败,网络原因且非403错误");
          }
        });
    },
  },
};
</script>

<style scoped>
.el-button {
  margin: 0.3em 0.8em;
}
</style>
