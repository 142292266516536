<template>
  <div class="CardComp" :v-ref="myID">
    <!-- question part -->
    <h4>{{ Fidx }}: {{ card.question }}</h4>
    <p v-if="card.has_hint" class="hint">{{ card.hint }}</p>
    <!-- different kind of input by type -->
    <div v-if="card.type == 0">
      <el-row>
        <el-col :xs="1" :sm="2" :md="3" :lg="5" :xl="6" />
        <el-col :xs="22" :sm="20" :md="18" :lg="14" :xl="12" class="table-wrap">
          <el-input type="text" v-model="value" />
        </el-col>
      </el-row>
      <!-- <div style="height: 1em"></div> -->
    </div>

    <div v-else-if="card.type == 1">
      <el-select v-model="value" class="m-2">
        <!-- <option :value="ans" v-for="(ans, idx) in card.alist" :key="idx">
          {{ ans }}
        </option> -->
        <el-option
          v-for="(ans, idx) in card.alist"
          :value="ans"
          :key="idx"
          :label="ans"
        />
      </el-select>
      <div style="height: 1em"></div>
    </div>

    <div v-else-if="card.type == 2" style="text-align: ce">
      <!-- <label v-for="(ans, idx) in card.alist" :key="idx"
        ><input
          type="checkbox"
          :value="ans"
          v-model="multiData"
          @change="multiDataConvert" />{{ ans }}<br
      /></label> -->
      <el-checkbox-group v-model="multiData" @change="multiDataConvert">
        <el-checkbox v-for="(ans, idx) in card.alist" :key="idx" :label="ans">
          {{ ans }}
        </el-checkbox>
      </el-checkbox-group>
      <!--  -->
      <div style="height: 1em"></div>
    </div>

    <div v-else-if="card.type == 3">
      <input type="hidden" v-model="value" />
      <!-- <input
        type="button"
        value="上传文件"
        name=""
        id=""
        @click="update"
        v-if="!fileName"
      /> -->
      <el-button @click="update" v-if="!fileName"> 上传文件</el-button>
      <input
        v-if="ishowFile"
        type="file"
        style="display: none"
        @change="getFile"
        id="fileSelector"
      />
      <div v-if="fileName">
        <p>上传的文件名：{{ fileName }}</p>
        <el-button @click="delFile">清空文件</el-button>
      </div>
      <div style="height: 1em"></div>
    </div>
    <div v-else><p>Card Type Error</p></div>
    <!-- <p>{{ resData }}</p> -->
  </div>
</template>
<!-- data
type0: input
type1: select-one
type2: multi-select
type3: file upload -->
<script>
import $ from "jquery";
export default {
  name: "CardComp",
  data() {
    return {
      myID: "p" + this.card.id,
      resData: null,
      multiData: [],
      fileName: "",
      ishowFile: true,
      value: "",
    };
  },
  props: {
    card: Object,
    Fidx: Number,
  },
  methods: {
    update() {
      // console.log("Select file");
      $("#fileSelector").click();
      this.ishowFile = false;
    },
    getFile(e) {
      // change事件
      console.log("onChange");
      if (e.target.files[0].size <= this.card.limit) {
        this.fileName = e.target.files[0].name;
        this.toBase64(e);
      } else {
        alert("文件过大请重新选择");
      }
      this.ishowFile = true;
    },
    setValue(v) {
      // console.log(v);
      this.value = v.split(",")[1];
    },
    toBase64(e) {
      // console.log("Converting to base64");
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.setValue(e.target.result);
      };
    },
    delFile() {
      this.fileName = "";
      this.value = "";
    },
    multiDataConvert() {
      this.value = this.multiData;
    },
  },
};
</script>

<style scoped>
.CardComp {
  background: var(--el-color-primary-light-8);
  border: 1px solid #5f5f5f;
  align-items: center;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hint {
  color: var(--el-color-info);
  font-size: 80%;
}
</style>
