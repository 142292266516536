<template>
  <div class="StateComp">
    <p style="color: green">申请ID: {{ state.id }}</p>
    <p>申请者：{{ state.username }}</p>
    <p>申请时间: {{ create_at_local }}</p>
    <p>申请轮次: {{ state.roundStr }}</p>
    <p>申请社团/部门：{{ state.organizationStr }}/{{ state.departmentStr }}</p>
    <p>
      第<span>{{ state.caseType }}</span
      >志愿
    </p>
    <p>
      阶段: <span>{{ state.status.name }}</span>
    </p>
    <div style="color: blue">
      {{ convert(state.message) }}
    </div>
    <div v-if="logInfo.length" style="color: blue">
      <p>系统或面试官回复消息:</p>
      <div
        v-for="(info, idx) in logInfo"
        :key="idx"
        :class="{ firstInfo: idx == 0 }"
      >
        <p>
          {{ ModifyTimeLocal[idx] }} [{{ info.TargetStateName }}] <br />
          {{ info.Hint }}
        </p>
      </div>
    </div>
    <div v-if="formsInfo.length" style="color: blue">
      <p>已提交表单:</p>
      <div
        v-for="(info, idx) in formsInfo"
        :key="idx"
        :class="{ firstInfo: idx == 0 }"
      >
        <a
          v-bind:href="
            '/api/case/' + state.id + '/forms/' + info.SubmitID + '.pdf'
          "
          >{{ info.FormName }} - {{ info.SubmitTime }}</a
        >
      </div>
    </div>
    <el-button type="primary" @click="getCaseLog()">查看日志</el-button>
    <el-button type="primary" @click="getForms()">查看已提交表单</el-button>

    <div class="actions">
      <ActionComp
        v-for="(action, idx) in state.actions"
        :key="idx"
        :action="action"
        :caseID="state.id"
      />
    </div>
  </div>
</template>

<script>
import ActionComp from "@/components/ActionComp.vue";
import axioService from "@/services/axioService.js";
export default {
  name: "StateComp",
  components: { ActionComp },
  data() {
    return {
      create_at_local: "",
      ModifyTimeLocal: [],
      logInfo: [],
      formsInfo: [],
    };
  },
  props: {
    state: Object,
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state: function (newVal, oldVal) {
      this.logInfo.splice(0);
      this.formsInfo.splice(0);
      this.create_at_local = new Date(this.state.create_at).toString();
    },
  },
  created() {
    this.create_at_local = new Date(this.state.create_at).toString();
  },
  methods: {
    convert(msg) {
      let ret = msg;
      const e = msg;
      let tmp = e.matchAll(/<interview>\((\d+),(\d+),(.+)\)/g).next();
      if (!tmp.done) {
        tmp = tmp.value;
        tmp[1] = new Date(parseInt(tmp[1]) * 1000);
        tmp[2] = new Date(parseInt(tmp[2]) * 1000);
        ret =
          "面试时间:" +
          tmp[1].toLocaleString() +
          " -> " +
          tmp[2].toLocaleString() +
          "; 面试地点:" +
          tmp[3];
      }
      return ret;
    },
    getForms() {
      let apiUrl = "/api/case/" + this.state.id + "/forms";
      axioService
        .getData(apiUrl)
        .then((res) => {
          if (res.data.code === 0) {
            this.formsInfo = res.data.data;
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
    getCaseLog() {
      let apiUrl = "/api/case/" + this.state.id + "/log";
      axioService
        .getData(apiUrl)
        .then((res) => {
          if (res.data.code === 0) {
            this.logInfo = res.data.data.logs;
            this.logInfo.sort((a, b) => (a.ModifyTime > b.ModifyTime ? -1 : 1));
            for (let i = 0; i < this.logInfo.length; i++) {
              const e = this.logInfo[i];
              this.logInfo[i].Hint = this.convert(this.logInfo[i].Hint);

              let ModifyTime = new Date(e.ModifyTime);
              this.ModifyTimeLocal.push(
                "<" +
                  ModifyTime.getFullYear() +
                  "-" +
                  (ModifyTime.getMonth() + 1) +
                  "-" +
                  ModifyTime.getDate() +
                  " " +
                  ModifyTime.getHours() +
                  ":" +
                  ModifyTime.getMinutes() +
                  ":" +
                  ModifyTime.getSeconds() +
                  ">"
              );
            }
          } else {
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 403) {
            window.location.replace(this.loginRedirectURL);
          }
        });
    },
  },
};
</script>

<style scoped>
.StateComp {
  width: 75%;
  max-width: 600px;
  min-height: 60px;
  margin: 20px auto;
  padding: 8px;
  background-color: beige;
  border: 2px solid black;
  border-radius: 20px;
}
span {
  color: red;
}
.firstInfo {
  border: 2px solid black;
}
</style>
