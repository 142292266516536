<template>
  <div class="PeopleComp">
    <p style="color: green">case_id: {{ people.case_id }}</p>
    <p>name: {{ people.name }}</p>
    <p>开始时间: {{ displyTime }}</p>
    <p>时长：{{ people.last }}</p>
    <p>confirmed: {{ people.confirmed }}</p>
  </div>
</template>

<script>
export default {
  name: "PeopleComp",
  data() {
    return {
      displyTime: "",
    };
  },
  props: {
    people: Object,
  },
  created() {
    this.displyTime = new Date(this.people.start_time).toLocaleString();
  },
};
</script>

<style scoped>
.PeopleComp {
  width: 85%;
  max-width: 550px;
  min-height: 24px;
  margin: 8px auto;
  padding: 3px;
  background-color: pink;
  border: 2px solid black;
  border-radius: 20px;
}
span {
  color: red;
}
</style>
