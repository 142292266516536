import axios from "axios";

const apiClient = axios.create({
  baseURL: "/",
  headers: {},
});

export default {
  getData(url) {
    return apiClient.get(url);
  },
  postData(url, d) {
    return apiClient.post(url, d);
  },
  patchData(url, d) {
    return apiClient.patch(url, d);
  },
};
