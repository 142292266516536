<template>
  <div class="aboutus">
    <table class="center">
      <td>
        <p>
          <i aria-hidden="true" class="weixin"></i>
          扫码关注微信平台：XJTUANA
        </p>
        <p>
          <img
            src="../assets/xjtuana_wechat.jpg"
            alt=""
            class="qrcode"
            style="width: 100px; height: 100px"
          />
        </p>
      </td>
      <td>
        <p>
          <i aria-hidden="true" class="qq"></i>
          扫码加入招新咨询群：882622842
        </p>
        <p>
          <img
            src="../assets/xjtuana_qq.jpg"
            alt=""
            class="qrcode"
            style="width: 100px; height: 100px"
          />
        </p>
      </td>
    </table>
  </div>
  <div id="copyright">
    <p>
      Copyright © XJTUANA <font id="year">2024</font>. All rights reserved.
      <a
        class="icp"
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        >陕ICP备2021013389号-1</a
      >
    </p>
  </div>
</template>

<style scoped>
.center {
  margin-left: auto;
  margin-right: auto;
}

.icp {
  color: var(--el-color-info-dark-2);
}
</style>
